import React, { useEffect, useState } from "react";
import { Application } from "./ProjectApplication";
import { useGetProjectApplication } from "../useGetProjectApplication";
import toast from "react-hot-toast";
import {
  FileText,
  Globe,
  Phone,
  Mail,
  Briefcase, 
  CreditCard,
  BarChart,
  MapPin,
  Image,
  Loader2,
  CheckCircle,
  Calendar,
  DollarSign,
 
} from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Cloudy, Droplet, Handshake, Leaf, TreePine, Zap, Info, Heart, ChevronLeft, ChevronRight, Trash2 } from 'lucide-react';
import { TbBatteryVerticalCharging } from "react-icons/tb";
import { PiWavesLight } from "react-icons/pi";
import { LiaShoppingBagSolid } from "react-icons/lia";
import { Wrench } from 'lucide-react';

const ProjectDetails = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { getProjectById } = useGetProjectApplication();
  const [formData, setFormData] = useState<Application>();
  const [loading, setLoading] = useState(true);

  const impactTypes = [
    {
      id: 'tree',
      icon: <TreePine color="green" size={32} strokeWidth={1.5} />,
      label: 'Trees Planted',
      description: 'Number of clearly documented and verifiably planted trees',
      unit: ''
    },
    {
      id: 'water',
      icon: <Droplet color="#3b82f6" size={32} strokeWidth={1.5} />,
      label: 'Water Saved',
      description: 'Amount of water conserved through sustainable practices',
      unit: 'L'
    },
    {
      id: 'air',
      icon: <Cloudy color="#6b7280" size={32} strokeWidth={1.5} />,
      label: 'CO₂ Reduced',
      description: 'Amount of CO2 emissions clearly documented and verifiably reduced or offset',
      unit: 'kg'
    },
    {
      id: 'social',
      icon: <Handshake color="#8b5cf6" size={32} strokeWidth={1.5} />,
      label: 'People Helped',
      description: 'Number of people clearly supported and positively impacted',
      unit: ''
    },
    {
      id: 'sustainability',
      icon: <Leaf color="#10b981" size={32} strokeWidth={1.5} />,
      label: 'Biodiversity Protected',
      description: 'Area clearly protected through conservation efforts',
      unit: '%'
    },
    {
      id: 'energy-produced',
      icon: <Zap color="#eab308" size={32} strokeWidth={1.5} />,
      label: 'Energy Produced',
      description: 'Amount of energy clearly produced from renewable sources',
      unit: 'kWh'
    },
    {
      id: 'energy-saved',
      icon: <TbBatteryVerticalCharging color='red' size={32} strokeWidth={1.5} />,
      label: 'Energy Saved',
      description: 'Amount of energy clearly saved through efficiency measures',
      unit: 'kWh'
    },
    {
      id: 'plastic',
      icon: <PiWavesLight color='#00b4d8' size={32} strokeWidth={1.5} />,
      label: 'Plastic Collected',
      description: 'Amount of plastic waste clearly removed from nature',
      unit: 'kg'
    },
    { 
      id: "sustainable-products",
      icon: <LiaShoppingBagSolid color='#00b4d8' size={32} strokeWidth={0.5} />,
      label: 'Sustainable Products Produced',
      description: 'Number of eco-friendly products clearly integrated',
      unit: ''
    },
    {
      id: "jobs-produced",
      icon: <Wrench size={32} strokeWidth={1.5} color="black" />,
      label: 'Fair Jobs',
      description: 'Number of sustainable and fair jobs created',
      unit: ''
    },
    {
      id: "clean-water",
      icon: <Heart size={32} strokeWidth={1.5} color="#00b4d8" />,
      label: 'Clean Water',
      description: 'Clean Water Provided',
      unit: 'L'
    },
    {
      id: "waste",
      icon: <Trash2 size={32} strokeWidth={1.5} color="black" />,
      label: 'Waste Reduced',
      description: 'Amount of waste clearly avoided or recycled',
      unit: 'kg'
    }
  ];

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await getProjectById(String(projectId));
        console.log("RESPONSE IN BY ID : ", response);
        setFormData(response);
      } catch (error) {
        console.log("Some Error occured While Fetching project detail by ID");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  const onBack = () => {
    navigate("/projectapplication");
  };

  const {
    rejectApplication,
    acceptApplication,
    approvalLoading,
    rejectLoader,
  } = useGetProjectApplication();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState<"approve" | "reject" | null>(
    null,
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [reason, setReason] = useState("");

  if (loading) {
    return (
      <div className="flex items-start text-2xl justify-center h-screen">
        Loading...
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="flex items-start  text-2xl justify-center h-screen">
        No Data Found , Please check the Project ID again .
      </div>
    );
  }

  const handleAction = async () => {
    setIsProcessing(true);
    const actionFn =
      actionType === "approve" ? acceptApplication : rejectApplication;

    try {
      const response = await actionFn(formData._id, reason); 

      toast.success(
        actionType === "approve"
          ? "Project approved successfully!"
          : "Project rejected successfully!",
      );
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      onBack();
      setIsProcessing(false);
      setShowConfirmationModal(false);
      setActionType(null);
      setReason(""); 
    }
  };

  const initiateAction = (type: "approve" | "reject") => {
    setActionType(type);
    setShowConfirmationModal(true);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "approved":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "pending":
        return "text-orange-500";
      default:
        return "";
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6 bg-white rounded-lg shadow">
      <header className="border-b pb-4">
        <h1 className="text-2xl font-bold text-emerald-600">
          Project Details
          <span
            className={getStatusColor(formData.approvalStatus) + " text-sm"}
          >
            {"        (" + formData.approvalStatus + ")"}
          </span>
        </h1>
        <p className="text-gray-500">
          Review the selected project details below
        </p>
      </header>

      <section className="space-y-6">
        {/* Project Information */}
        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <Briefcase className="w-5 h-5 mr-2 text-emerald-600" />
            Project Information
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
  {/* Left Column */}
  <div className="space-y-4">
    <div>
      <label className="text-gray-500 text-sm">Project Logo</label>
      <img
        src={formData.logo}
        alt="project logo"
        className="w-20 h-20 object-cover rounded"
      />
    </div>
    <div>
      <label className="text-gray-500 text-sm">Project Name</label>
      <p className="font-medium">{formData.name}</p>
    </div>
    <div>
      <label className="text-gray-500 text-sm flex items-center">
        <Mail className="w-4 h-4 mr-1" />
        Email Address
      </label>
      <p className="font-medium">{formData.email}</p>
    </div>
    <div>
      <label className="text-gray-500 text-sm flex items-center">
        <Phone className="w-4 h-4 mr-1" />
        Phone Number
      </label>
      <p className="font-medium">{formData.phoneNumber}</p>
    </div>
  </div>

  {/* Right Column */}
  <div className="space-y-4">
    <div>
      <label className="text-gray-500 text-sm">Project Symbol</label>
      <p className="font-medium">{formData.symbol}</p>
    </div>
    <div>
      <label className="text-gray-500 text-sm flex items-center">
        <Globe className="w-4 h-4 mr-1" />
        Website
      </label>
      <a
        href={formData.website}
        target="_blank"
        rel="noopener noreferrer"
        className="text-emerald-600 hover:underline"
      >
        {formData.website}
      </a>
    </div>
    <div>
      <label className="text-gray-500 text-sm">Description</label>
      <p className="font-medium line-clamp-2">{formData.description}</p>
    </div>


      <div >
        <label className="text-gray-500 text-sm flex items-center">
        
          Profitability
        </label>
        <p>{formData.isProfitable ? "Profitable":"Non-Profitable"}</p>
        
      </div>  
       
  
 
    {formData.isProfitable && (
      <div>
        <label className="text-gray-500 text-sm flex items-center">
          <Calendar className="w-4 h-4 mr-1" />
          Investment Cutoff Date
        </label>
        <p className="font-medium">{new Date(formData.investmentCutoffDate).toLocaleDateString()}</p>
      </div>
    )}
  </div>
</div>

        </div>

      {formData.isProfitable &&  <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <CreditCard className="w-5 h-5 mr-2 text-emerald-600" />
            Financial Details
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">
                  Required Investment
                </label>
                <p className="font-medium">{formData.requiredInvestment}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">
                  Percentage To Burn
                </label>
                <p className="font-medium">{formData.burn}%</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Min Investment</label>
                <p className="font-medium">{formData.minInvestment}</p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Lock In Period</label>
                <p className="font-medium">{formData.lockInPeriod}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">
                  Max Token Supply
                </label>
                <p className="font-medium">{formData.maxSupply}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">
                  Accepted Currencies
                </label>
                <div className="flex flex-wrap gap-2 mt-1">
                  {formData.currenciesAccepted?.length > 0 ? (
                    formData.currenciesAccepted.map((currency) => (
                      <span
                        key={currency._id}
                        className="px-2 py-1 bg-emerald-100 text-emerald-700 rounded text-sm"
                      >
                        {currency.name}
                      </span>
                    ))
                  ) : (
                    <span className="text-gray-500">
                      No currencies selected
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        }

        
        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <BarChart className="w-5 h-5 mr-2 text-emerald-600" />
            Market Details
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Category</label>
                <p className="font-medium">{formData?.category?.name || ""}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Project Stage</label>
                <p className="font-medium">{formData.projectStage}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <MapPin className="w-4 h-4 mr-1" />
                  Geographic Market
                </label>
                <p className="font-medium">{formData.geographicMarket}</p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <FileText className="w-4 h-4 mr-1" />
                  Documents
                </label>
                <div className="space-y-2">
                  {formData.balanceSheet && (
                    <a
                      href={formData.balanceSheet}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-emerald-600 hover:underline"
                    >
                      Balance Sheet
                    </a>
                  )}
                  {formData?.otherDocument?.map((doc, index) => (
                    <a
                      key={index}
                      href={doc}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-emerald-600 hover:underline"
                    >
                      Document {index + 1}
                    </a>
                  ))}
                </div>
              </div>
              <div>
                <label className="text-gray-500 text-sm flex items-center">
                  <Image className="w-4 h-4 mr-1" />
                  Images
                </label>
                <div className="flex gap-2 mt-2">
                  {formData?.images?.map((img, index) => (
                    <img
                      key={index}
                      src={img}
                      alt={`Project image ${index + 1}`}
                      className="w-20 h-20 object-cover rounded"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <BarChart className="w-5 h-5 mr-2 text-emerald-600" />
            Additional Information
          </h2>

          <div>
        <div className="w-full items-start mt-10 mb-5">
          {/* <p className="text-2xl font-semibold text-black pl-2">Information</p> */}
        </div>
        <div className="overflow-x-auto">
            <div className="flex space-x-4 pb-4">
              {formData?.projectPhases && formData?.projectPhases.map((phase, idx) => (
                <div className="flex-none w-80" key={idx}>
                <div className="relative bg-gray-100 h-40 rounded-2xl p-3 border border-emerald-500 flex flex-col">
                  <div className="flex items-start h-12 min-h-[3rem]">
                    <h3 className="flex-1 font-semibold text-emerald-600 text-sm break-words mr-2 line-clamp-2">
                      {phase.phaseName || "N/A"}
                    </h3>
                    <div className="flex items-center space-x-2"> 
                      {phase.documentUrl && (
                        <a href={phase.documentUrl} target="_blank" rel="noopener noreferrer"> 
                          <FileText className="w-5 h-5 text-emerald-600 hover:text-emerald-800" />
                        </a>
                      )}
                      
                    </div> 
                  </div>
    
                  <div className="mt-2 flex-1 overflow-y-auto">
                    <p className="text-gray-700 text-xs whitespace-pre-wrap break-words">
                      {phase.phaseDescription || "No description provided"}
                    </p>
                  </div>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
        </div>

        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <BarChart className="w-5 h-5 mr-2 text-emerald-600" />
            Environmental Impact
          </h2>
          <div className="bg-[#fafafa] border rounded-[12px] p-4">
      <div className="overflow-x-auto">
                <div className="flex space-x-4 pb-4">
                <div className="overflow-x-auto">
                <div className="flex space-x-4 pb-4">
                {formData.impactData && formData.impactData.length > 0 && (
        <div>
          <div className="flex  pb-3">
            {formData.impactData.map((item, idx) => {
              const impactType = impactTypes.find(type => type.id === item.icon);
              return (
                <div key={idx} className="cursor-pointer flex-shrink-0 flex flex-col items-center justify-center p-2 rounded-lg border mx-1 min-w-[200px] gap-x-4 h-24">
                  <div className="absolute top-2 right-2">
                   
                  </div>
                  
                  <div className="flex items-center">
                    <div className="mr-3">
                      {impactType?.icon}
                    </div>
                    <div>
                      <div className="text-lg font-medium">
                        {item.value}{item.unit}
                      </div>
                      <div className="text-sm text-gray-600">
                        {impactType?.label}
                      </div>
                      <div className="mt-2">
                        <span className={`text-xs px-2 py-1 rounded-full ${
                          item.type === 'expected' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                        }`}>
                          {item.type === 'expected' ? 'Expected' : 'Current'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
                </div>
              </div>
                </div>
              </div>
              </div>
        </div>

        {/* Actions */}
        <div className="flex items-center justify-between pt-4 border-t">
          <button
            onClick={onBack}
            disabled={approvalLoading || rejectLoader}
            className="text-emerald-600 hover:underline"
          >
            ← Back
          </button>

          {formData.approvalStatus === "pending" && (
            <div className="space-x-3">
              <button
                onClick={() => initiateAction("approve")}
                disabled={approvalLoading || rejectLoader}
                className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 disabled:opacity-50"
              >
                Approve
              </button>

              <button
                onClick={() => initiateAction("reject")}
                disabled={approvalLoading || rejectLoader}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:opacity-50"
              >
                Reject
              </button>
            </div>
          )}
        </div>
      </section>

      {/* Confirmation Modal */}
      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-96">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to {actionType} this application?
            </p>

            {/* Show input field for rejection reason if actionType is 'reject' */}
            {actionType === "reject" && (
              <div className="mb-4">
                <label className="text-sm text-gray-500">
                  Reason for rejection
                </label>
                <textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Provide a reason"
                  className="w-full p-2 mt-2 border rounded-md"
                  rows={4}
                />
              </div>
            )}

            <div className="flex justify-end space-x-3">
              <button
                disabled={isProcessing}
                onClick={() => {
                  setShowConfirmationModal(false);
                  setActionType(null);
                  setReason(""); // Clear reason when modal is closed
                }}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 disabled:opacity-50"
              >
                Cancel
              </button>
              <button
                disabled={isProcessing}
                onClick={handleAction}
                className={`px-4 py-2 rounded-lg relative ${
                  actionType === "approve"
                    ? "bg-emerald-600 hover:bg-emerald-700"
                    : "bg-red-500 hover:bg-red-600"
                } text-white disabled:opacity-50`}
              >
                {isProcessing ? (
                  <span className="flex items-center justify-center">
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing...
                  </span>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
