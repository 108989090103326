// src/hooks/useLogin.ts
import { useState } from "react";
import { apiPaths } from "../../service/apiPaths";
import ApiService from "../../service/ApiService";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import qs from "qs";

export interface Currencies{
    name:String ,
    code:String ,
    symbol:String ,
    rate:String ,
    _id:String
}
export const useCurrency = () => {
  const [loading, setLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [approveLoader, setApproveLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


    const [currencies, setCurrencies] = useState<Currencies[]>([]);



  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await ApiService({
        method: 'POST',
        endpoint:apiPaths.updateValues , 
        data:{currencies}
      })

      toast.success('Rates Updated')
      console.log('Submitted rates:', currencies);
    } catch (error) {
      console.error('Error updating rates:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getConversions = async () => {
    setIsSubmitting(true);
    try {
      const response = await ApiService({
        method: 'GET',
        endpoint:apiPaths.getValues
      })
      console.log('Response:', response);
      setCurrencies(response.response)
    } catch (error) {
      console.error('Error updating rates:', error);
    } finally {
      setIsSubmitting(false);
    }
  };


  const createCurrency = async (newCurrency:{name:String , code:String , symbol:String , rate:String}) => {
    try {
      const response = await ApiService({
        method: 'POST',
        endpoint:apiPaths.createCurrency , 
        data:newCurrency
      })

      toast.success('Currency Created.')

      return response
    } catch (error) {
        return null
    } finally {
    }
  };


  const deleteCurrency = async(_id:String)=>{
    try{
        const data = await ApiService({
            method:"DELETE" ,
            endpoint:`${apiPaths.deleteConversion}/${_id}`
        })
        toast.success('Currency Deleted')
    }catch(err){
        toast.error('Error Occured')
    }
  }


 

  return {
    handleSubmit ,
    getConversions ,
    
    deleteCurrency,
    isSubmitting,
    setIsSubmitting ,
    error,
    currencies ,
    setCurrencies ,
    approvalLoading,
    rejectLoader,
    createCurrency
  };
}
