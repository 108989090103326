import { useState } from "react";
import { apiPaths } from "../../service/apiPaths";
import ApiService from "../../service/ApiService";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import qs from "qs";

export const useGetInvestmentCutoff = () => {
  const [loading, setLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [approveLoader, setApproveLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);

  // Fetch all investment cutoffs
  const getAllInvestmentCutoffs = async (page: string): Promise<any | null> => {
    setLoading(true);
    setError(null);

    try {
      const response: any = await ApiService({
        method: "GET",
        endpoint: `${apiPaths.getAllInvestmentCutoffs}?page=${page}&limit=4`,
      });

      console.log(response.response.docs);
      console.log(response);

      return response.response; // Return the response data
    } catch (error: any) {
      const errorMessage = error.message || "Failed to fetch investment cutoffs";
      setError(errorMessage);
      toast.error("Fetch error:", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

 
  const rejectInvestmentCutoff = async (cutoffId: string, reason: string) => {
    try {
      setRejectLoader(true);

  
      const data = qs.stringify({
        reason: reason, 
      });

      const endpoint = `${process.env.REACT_APP_BASE_URL}/${apiPaths.getAllInvestmentCutoffs}/${cutoffId}/reject`;

      const response = await axios.post(endpoint, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", 
        },
        withCredentials: true, 
      });

      console.log(response.data); 
      return response.data;
    } catch (err) {
      console.error("Error rejecting investment cutoff:", err);
      return null;
    } finally {
      setRejectLoader(false);
    }
  };

  const approveInvestmentCutoff = async (cutoffId: string) => {
    try {
      setApprovalLoading(true);
      const data = await ApiService({
        method: "POST",
        endpoint: `${apiPaths.getAllInvestmentCutoffs}/${cutoffId}/approve`,
      });
      return data;
    } catch (err) {
      return null;
    } finally {
      setApprovalLoading(false);
    }
  };

  const getRejectedInvestmentCutoffs = async (page: string) => {
    try {
      setLoading(true);
      const response: any = await ApiService({
        method: "GET",
        endpoint: `${apiPaths.getRejectedInvestmentCutoffApplication}?page=${page}&limit=4`,
      });
      console.log(response);
      if (response.success) {
        return response.response;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getAcceptedInvestmentCutoffs = async (page: string) => {
    try {
      setLoading(true);
      const response: any = await ApiService({
        method: "GET",
        endpoint: `${apiPaths.getAcceptedInvestmentCutoffApplication}?page=${page}&limit=4`,
      });
      console.log(response);
      if (response.success) {
        return response.response;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    } finally {
      setLoading(false);
    }
  };


  const getInvestmentCutoffById = async (id: string | undefined) => {
    try {
      setLoading(true);
      const response: any = await ApiService({
        method: "GET",
        endpoint: `${apiPaths.getAllInvestmentCutoffs}/${id}`,
      });
      if (response.success) {
        console.log(response.response);
        return response.response;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    } finally {
      setLoading(false);
    }
  };


 

  return {
    getAllInvestmentCutoffs,
    rejectInvestmentCutoff,
    getInvestmentCutoffById,
    approveInvestmentCutoff,
    getRejectedInvestmentCutoffs,
    getAcceptedInvestmentCutoffs,
    loading,
    error,
    approvalLoading,
    rejectLoader
  };
};