import React, { useEffect, useState } from "react";
import prevButton from "../../../images/Explore/prev.png";
import nextButton from "../../../images/Explore/next.png";
import { useGetProjectManagers } from "../useGetProjectManager";
import ManagerApplication from "./ManagerApplication";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export interface ProfileResponse {
  _id: string;
  profilePic: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  isVerified: boolean;
  kycStatus: "pending" | "submitted" | "approved" | "rejected";
  roleId: string;
  preferences: {
    timezone: string | null;
    logoutTime: number;
  };
}

export interface ProjectManagerData {
  _id: string;
  phone: number;
  address: string;
  experience: number;
  certifications: string[];
  linkedInProfile: string;
  governmentId: string;
  isApproved: boolean;
  verificationStatus: string;
  website: string;
  referenceContact: number;
  userId: ProfileResponse;
  previousProjects: string[];
  createdAt:string
}

interface PaginationState {
  currentPage: number;
  totalPages: number;
  totalDocs: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

interface ProjectManagerResponse {
  docs: ProjectManagerData[];
  totalPages: number;
  totalDocs: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

const ProjectManager: React.FC = () => {
  const {
    getAllProjects,
    getRejectedApplication,
    rejectApplication,
    acceptApplication,
    getAcceptedApplication,
    loading,
  } = useGetProjectManagers();
  const [applications, setApplications] = useState<ProjectManagerData[]>([]);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<PaginationState>({
    currentPage: 1,
    totalPages: 1,
    totalDocs: 0,
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [selectedProject, setSelectedProject] =
    useState<ProjectManagerData | null>(null);
  const [filter, setFilter] = useState<string>("normal"); // Dropdown filter state

  useEffect(() => {
    async function fetchApplications() {
      try {
        let response: ProjectManagerResponse;

        if (filter === "rejected") {
          response = await getRejectedApplication(
            pagination.currentPage.toString(),
          );
          console.log(response);
        } else if (filter === "normal") {
          response = await getAllProjects(pagination.currentPage.toString());
        } else {
          response = await getAcceptedApplication(
            pagination.currentPage.toString(),
          );
        }
        console.log("hiiii");

        if (response) {
          setApplications(response.docs || []);
          setPagination((prev) => ({
            ...prev,
            totalPages: response.totalPages || 1,
            totalDocs: response.totalDocs || 0,
            hasNextPage: response.hasNextPage || false,
            hasPrevPage: response.hasPrevPage || false,
          }));
        }
      } catch (error) {
        console.error("Failed to fetch applications", error);
      }
    }

    fetchApplications();
  }, [pagination.currentPage, filter]);

  const handleNavigation = (managerId: string) => {
    navigate(`/projectmanager/${managerId}`);
  };

  const getPageNumbers = (): number[] => {
    const totalPages = pagination.totalPages;
    const currentPage = pagination.currentPage;
    const pageNumbers: number[] = [];

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 4) {
        pageNumbers.push(1, 2, 3, 4);
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        );
      } else {
        pageNumbers.push(
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        );
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (pageNumber: number): void => {
    setPagination((prev) => ({ ...prev, currentPage: pageNumber }));
  };

  const handleNextPage = (): void => {
    if (pagination.hasNextPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = (): void => {
    if (pagination.hasPrevPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleApprove = async (id: string) => {
    try {
      // Perform the approve action
      const response = await acceptApplication(id);
      console.log(response);

      if (response) {
        console.log("pinkkk");
        toast.success("Application Accepted");
        setApplications((prev) =>
          prev.filter((application) => application._id !== id),
        );

        setSelectedProject(null);
      } else {
        toast.error("Error Occurred");
      }

      // Remove the approved application from the state
    } catch (error) {
      toast.error("Error Occurred");
      console.error("Failed to approve application", error);
    } finally {
    }
  };

  const dateSanitizer =(mongoDate:string)=>{
    const date = new Date(mongoDate);

     const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${
      (date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
      return formattedDate
  }

  return (
    <>
      <div className="bg-[#fff] p-4 min-h-full rounded-[16px]">
        <h1 className="text-[20px] font-semibold">Project Manager</h1>
        <p className="text-[14px] text-[#9e9b9b] mt-2">
          View a list of active investors supporting your projects. Gain
          insights into their contributions and engagement, fostering
          connections and opportunities for collaboration.
        </p>

        <div className="flex justify-end mb-4">
          <select
            value={filter}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="border px-3 py-2 rounded-lg"
          >
            <option value="normal">Active Applications</option>
            <option value="rejected">Rejected Applications</option>
            <option value="accepted">Accepted Applications</option>
          </select>
        </div>

        {loading ? (
          <div className="w-full h-[16rem] flex items-center justify-center">
            <p>Loading...</p>
          </div>
        ) : (
          <div className="bg-white border rounded-lg overflow-hidden">
            <h1 className="ml-3 my-3 font-semibold">
              {filter === "rejected"
                ? "Rejected Applications"
                : "Active Applications"}
            </h1>
            {applications && applications.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full shadow-sm">
                  <thead>
                    <tr className="bg-gray-100 text-gray-600 text-sm uppercase">
                      <th className="py-3 px-6 text-left">Sr. No.</th>
                      <th className="py-3 px-6 text-left">Name</th>
                      <th className="py-3 px-6 text-left">Phone Number</th>
                      <th className="py-3 px-6 text-left">Experience(yrs)</th>
                      <th className="py-3 px-6 text-left">Date</th>
                      <th className="py-3 px-6 text-left">View</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-sm font-light">
                    {applications.map((project, index) => (
                      <tr
                        key={project?._id}
                        className="border-b border-gray-200 hover:bg-gray-50"
                      >
                        <td className="py-3 px-6">
                          {(pagination.currentPage - 1) * 4 + index + 1}
                        </td>
                        <td className="py-3 px-6">
                          {project?.userId?.fullName}
                        </td>
                        <td className="py-3 px-6">
                          {project?.phone?.toString()}
                        </td>
                        <td className="py-3 px-6">
                          {project.experience.toString()}
                        </td>
                      
                        <td className="py-3 px-6">
                          {dateSanitizer(project.createdAt)}
                        </td>
                        <td
                          className="py-3 px-6 hover:cursor-pointer text-green-400" 
                          onClick={() => {
                            handleNavigation(project._id);
                          }}
                        >
                          View
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="w-full h-[16rem] flex items-center justify-center">
                <p className="text-xl">No Application Found</p>
              </div>
            )}
          </div>
        )}

        {applications && applications.length > 0 && (
          <div className="flex w-full justify-center mx-auto gap-2 mt-6 mb-1">
            <button
              onClick={handlePrevPage}
              disabled={!pagination.hasPrevPage}
              className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${
                !pagination.hasPrevPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <img
                className="h-[10px] w-[7px]"
                src={prevButton}
                alt="Previous"
              />
            </button>
            {getPageNumbers().map((pageNumber, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(pageNumber)}
                className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${
                  pagination.currentPage === pageNumber
                    ? "bg-[#56BBAC] text-white"
                    : ""
                }`}
              >
                {pageNumber}
              </button>
            ))}
            <button
              onClick={handleNextPage}
              disabled={!pagination.hasNextPage}
              className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${
                !pagination.hasNextPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <img className="h-[10px] w-[7px]" src={nextButton} alt="Next" />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectManager;
