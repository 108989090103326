import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetInvestmentCutoff } from "./useInvestmentCutoff";
import toast from "react-hot-toast";
import {
  Clock,
  FileText,
  DollarSign,
  Calendar,
  Loader2
} from "lucide-react";
import { ProfileResponse } from "../Profile/Profile.props";
import { Application } from "../ProjectApplication/Components/ProjectApplication";

type Status = 'pending' | 'accepted' | 'rejected';

interface InvestmentCutoffApplication {
  _id: string;
  description: string;
  duration: string;
  userId: ProfileResponse;
  projectId: Application;
  status: Status;
  createdAt: string;
  updatedAt: string;
}

type ActionType = 'approve' | 'reject' | null;

const InvestmentCutoffDetails: React.FC = () => {
  const navigate = useNavigate();
  const { cutoffId } = useParams();
  const { getInvestmentCutoffById, approveInvestmentCutoff, rejectInvestmentCutoff } = useGetInvestmentCutoff();
  const [formData, setFormData] = useState<InvestmentCutoffApplication | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ActionType>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");

  useEffect(() => {
    const fetchCutoffDetails = async () => {
      try {
        const response = await getInvestmentCutoffById(String(cutoffId));
        setFormData(response);
      } catch (error) {
        console.log("Error occurred while fetching cutoff details");
      } finally {
        setLoading(false);
      }
    };

    fetchCutoffDetails();
  }, [cutoffId]);

  const onBack = () => {
    navigate("/investment-cutoff-applications");
  };

  const handleAction = async () => {
    if (!formData) return;

    setIsProcessing(true);
    const actionFn = actionType === "approve" ? approveInvestmentCutoff : rejectInvestmentCutoff;

    try {
      const response = await actionFn(formData._id, reason);
      toast.success(
        actionType === "approve"
          ? "Investment cutoff approved successfully!"
          : "Investment cutoff rejected successfully!"
      );
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      onBack();
      setIsProcessing(false);
      setShowConfirmationModal(false);
      setActionType(null);
      setReason("");
    }
  };

  const initiateAction = (type: ActionType) => {
    setActionType(type);
    setShowConfirmationModal(true);
  };

  const getStatusColor = (status: Status): string => {
    switch (status) {
      case "accepted":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "pending":
        return "text-orange-500";
      default:
        return "";
    }
  };

  if (loading) {
    return (
      <div className="flex items-start text-2xl justify-center h-screen">
        Loading...
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="flex items-start text-2xl justify-center h-screen">
        No Data Found, Please check the Investment Cutoff ID again.
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6 bg-white rounded-lg shadow">
      <header className="border-b pb-4">
        <h1 className="text-2xl font-bold text-emerald-600">
          Investment Cutoff Details
          <span className={`${getStatusColor(formData.status)} text-sm`}>
            {" (" + formData.status + ")"}
          </span>
        </h1>
        <p className="text-gray-500">Review the investment cutoff request details below</p>
      </header>

      <section className="space-y-6">
        {/* Basic Information */}
        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <Clock className="w-5 h-5 mr-2 text-emerald-600" />
            Cutoff Information
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Extension Requested (months)</label>
                <p className="font-medium">{formData.duration}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Description</label>
                <p className="font-medium">{formData.description}</p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Project Manager</label>
                <p className="font-medium">
                  {formData.userId.fullName}
                </p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Created Date</label>
                <p className="font-medium">
                  {new Date(formData.updatedAt).toLocaleDateString()}
                </p>
              </div> 
            </div>
          </div>
        </div>

        <div className="bg-gray-50 rounded-xl p-6 shadow-sm">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <FileText className="w-5 h-5 mr-2 text-emerald-600" />
            Project Details
          </h2>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Project Name</label>
                <p className="font-medium">{formData.projectId?.name || "N/A"}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Min Investment</label>
                <p className="font-medium">{formData.projectId?.minInvestment || "N/A"}</p>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-500 text-sm">Required Investment</label>
                <p className="font-medium">{formData.projectId?.requiredInvestment || "N/A"}</p>
              </div>
              <div>
                <label className="text-gray-500 text-sm">Investment Raised</label>
                <p className="font-medium">{formData.projectId.investmentRaised || "0"}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="flex items-center justify-between pt-4 border-t">
          <button
            onClick={onBack}
            className="text-emerald-600 hover:underline"
          >
            ← Back
          </button>

          {formData.status === "pending" && (
            <div className="space-x-3">
              <button
                onClick={() => initiateAction("approve")}
                disabled={isProcessing}
                className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 disabled:opacity-50"
              >
                Approve
              </button>
              <button
                onClick={() => initiateAction("reject")}
                disabled={isProcessing}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:opacity-50"
              >
                Reject
              </button>
            </div>
          )}
        </div>
      </section>

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-96">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to {actionType} this investment cutoff request?
            </p>

            {actionType === "reject" && (
              <div className="mb-4">
                <label className="text-sm text-gray-500">
                  Reason for rejection
                </label>
                <textarea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Provide a reason"
                  className="w-full p-2 mt-2 border rounded-md"
                  rows={4}
                />
              </div>
            )}

            <div className="flex justify-end space-x-3">
              <button
                disabled={isProcessing}
                onClick={() => {
                  setShowConfirmationModal(false);
                  setActionType(null);
                  setReason("");
                }}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 disabled:opacity-50"
              >
                Cancel
              </button>
              <button
                disabled={isProcessing}
                onClick={handleAction}
                className={`px-4 py-2 rounded-lg relative ${
                  actionType === "approve"
                    ? "bg-emerald-600 hover:bg-emerald-700"
                    : "bg-red-500 hover:bg-red-600"
                } text-white disabled:opacity-50`}
              >
                {isProcessing ? (
                  <span className="flex items-center justify-center">
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing...
                  </span>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestmentCutoffDetails;