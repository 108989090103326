import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./css/global.css";
import ForgotPassword from "./components/Authentication/ForgotPassword/ForgotPassword";
import VerifyCode from "./components/Authentication/VerifyOtp/VerifyCode";
import NewPassword from "./components/Authentication/NewPassword";
import Signup from "./components/Authentication/Signup/Signup";
import DefaultLayout from "./layout/DefaultLayout";
import DashboardIndex from "./page/Dashboard";
import SettingsIndex from "./page/Settings";
import Notification from "./page/Settings/Components/Notification";
import Preference from "./page/Settings/Components/Preference";
import ProfileIndex from "./page/Profile";
import ListProjectIndex from "./page/ListProject";
import Login from "./components/Authentication/Login/Login";
import ProjectManagerIndex from "./page/ProjectManager";
import ProjectApplicationIndex from "./page/ProjectApplication";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/appStore";
import ApiService from "./service/ApiService";
import { apiPaths } from "./service/apiPaths";
import { setIsLoggedIn } from "./redux/userSlice";
import ProjectDetails from "./page/ProjectApplication/Components/ProjectDetails";
import ManagerApplication from "./page/ProjectManager/Components/ManagerApplication";
import Application from "./page/Withdrawals/Components/Application";
import ApplicationDetails from "./page/Withdrawals/Components/ApplicationDetails";
import logo from "../src/images/logo/fulllogo.png"
import InvestmentCutoffPage from "./page/InvestmentCutoff/InvestmentCutoff";
import InvestmentCutoffDetails from "./page/InvestmentCutoff/InvestmentCutoffDetails";
import CurrencyRates from "./page/Currency/CurrencyRates";


function App() {
  const isLoggedIn = useSelector((store: RootState) => store?.user?.isLoggedIn);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  // Example token verification logic
  useEffect(() => {
    // Verify token on app load or page refresh
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      const response = await ApiService({
        method: "GET",
        endpoint: apiPaths.checkTokens,
      });
      if (response?.response) {
        console.log("TOKENS ARE VERIFIED");
        dispatch(setIsLoggedIn(true));
      } else {
        console.log("TOKENS ARE NOT VERIFIED");
        dispatch(setIsLoggedIn(false));
      }
    } catch (error) {
      console.log("TOKENS ARE NOT VERIFIED");
      dispatch(setIsLoggedIn(false));
    } finally {
      setIsLoading(false); // Set loading to false after verification
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
      <img src={logo}></img>
      
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/verify" element={<VerifyCode />} />
        <Route path="/newpassword/:forgotToken" element={<NewPassword />} />

        {/* Protected Routes (Require login) */}
        {isLoggedIn ? (
          <>
            <Route
              path="/"
              element={
                <DefaultLayout>
                  <DashboardIndex />
                </DefaultLayout>
              }
            />
            <Route
              path="/projectmanager"
              element={
                <DefaultLayout>
                  <ProjectManagerIndex />
                </DefaultLayout>
              }
            />
            <Route
              path="/projectmanager/:managerId"
              element={
                <DefaultLayout>
                  <ManagerApplication />
                </DefaultLayout>
              }
            />
            <Route
              path="/projectapplication"
              element={
                <DefaultLayout>
                  <ProjectApplicationIndex />
                </DefaultLayout>
              }
            />
            <Route
              path="/projectapplication/:projectId"
              element={
                <DefaultLayout>
                  <ProjectDetails />
                </DefaultLayout>
              }
            />

            <Route
              path="/withdrawapplication"
              element={
                <DefaultLayout>
                  <Application />
                </DefaultLayout>
              }
            />
            <Route
              path="/withdrawapplication/:projectId"
              element={
                <DefaultLayout>
                  <ApplicationDetails />
                </DefaultLayout>
              }
            />
            <Route
              path="/settings"
              element={
                <DefaultLayout>
                  <SettingsIndex />
                </DefaultLayout>
              }
            />
            <Route
              path="/settings/notification"
              element={
                <DefaultLayout>
                  <Notification />
                </DefaultLayout>
              }
            />
            <Route
              path="/settings/preference"
              element={
                <DefaultLayout>
                  <Preference />
                </DefaultLayout>
              }
            />
            <Route
              path="/profile"
              element={
                <DefaultLayout>
                  <ProfileIndex />
                </DefaultLayout>
              }
            /> 
            
            <Route
              path="/investment-cutoff-applications"
              element={
                <DefaultLayout>
                  <InvestmentCutoffPage />
                </DefaultLayout>
              }
            />
            <Route
              path="/investmentcutoff/:cutoffId"
              element={
                <DefaultLayout>
                  <InvestmentCutoffDetails />
                </DefaultLayout>
              }
            />
             <Route
              path="/currency-rates"
              element={
                <DefaultLayout>
                  <CurrencyRates />
                </DefaultLayout>
              }
            />

 


          </>
        ) : (
          // If not logged in, redirect to login page
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
