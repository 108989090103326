import React, { useEffect, useState } from "react";
import { ProjectManagerData } from "./ProjectManager";
import { useGetProjectManagers } from "../useGetProjectManager";
import toast from "react-hot-toast";
import {
  Phone,
  MapPin,
  Briefcase,
  Link,
  FileText,
  Award,
  Users,
  Globe,
  Image as ImageIcon,
  Loader2,
} from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// interface ManagerApplicationProps {
//   selectedProject: ProjectManagerData;
//   filter: string;
//   setApplications:React.Dispatch<React.SetStateAction<ProjectManagerData[]>>
//   setSelectedProject:React.Dispatch<React.SetStateAction<ProjectManagerData|null>>,
//   onBack: () => void;

// }

const LoaderModal: React.FC = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-6 rounded-md shadow-lg">
      <p className="text-lg font-semibold text-gray-700">Processing...</p>
    </div>
  </div>
);

const ManagerApplication = () => {
  const {
    approvalLoading,
    rejectLoader,
    rejectApplication,
    acceptApplication,
  } = useGetProjectManagers();
  const navigate = useNavigate();
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState<"approve" | "reject" | null>(
    null,
  );
  const [modalLoader, setModalLoader] = useState<boolean>(false);
  const location = useLocation();
  const { managerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState<ProjectManagerData>();
  const { getProjectManagerById } = useGetProjectManagers();

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await getProjectManagerById(String(managerId));
        console.log("RESPONSE IN BY ID : ", response);
        setSelectedProject(response);
      } catch (error) {
        console.log("Some Error occured While Fetching project detail by ID");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [managerId]);

  const onBack = () => {
    navigate("/projectmanager");
  };

  if (loading) {
    return (
      <div className="flex items-start text-2xl justify-center h-screen">
        Loading...
      </div>
    );
  }

  if (!selectedProject) {
    return (
      <div className="flex items-start  text-2xl justify-center h-screen">
        No Data Found , Please check the Project ID again .
      </div>
    );
  }

  const handleConfirmAction = async () => {
    setModalLoader(true);
    if (actionType === "approve") {
      await handleApprove(selectedProject._id);
    } else if (actionType === "reject") {
      await handleReject(selectedProject._id);
    }
    setModalLoader(false);
    setShowConfirmationModal(false);
    setActionType(null);
  };

  const handleApprove = async (id: string) => {
    try {
      const response = await acceptApplication(id);
      console.log(response);
      toast.success("Approved Successfully");
      // Remove the approved application from the state
    } catch (error) {
      toast.error("Error Occurred");
      console.error("Failed to approve application", error);
    } finally {
      onBack();
    }
  };

  const handleReject = async (id: string) => {
    try {
      const response = await rejectApplication(id, rejectionReason);
      toast.success("Rejected Successfully");

      // Remove the rejected application from the state
    } catch (error) {
      toast.error("Error Occurred");
      console.error("Failed to reject application", error);
    } finally {
      onBack();
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "approved":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "submitted":
        return "text-orange-500";
      default:
        return "";
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-6 bg-white rounded-lg shadow">
      <header className="border-b pb-4">
        <h1 className="text-2xl font-bold text-emerald-600">
          Project Manager Details
          <span
            className={
              getStatusColor(selectedProject.verificationStatus) + " text-sm"
            }
          >
            {"        (" + selectedProject.verificationStatus + ")"}
          </span>
        </h1>
        <p className="text-gray-500">
          Review the selected project manager details below
        </p>
      </header>

      {/* Personal Information */}
      <section className="bg-gray-50 rounded-xl p-6 shadow-sm">
        <h2 className="text-lg font-semibold mb-4 flex items-center">
          <Briefcase className="w-5 h-5 mr-2 text-emerald-600" />
          Personal Information
        </h2>

        <div className="grid md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div>
              <label className="text-gray-500 text-sm flex items-center">
                <Phone className="w-4 h-4 mr-1" />
                Phone Number
              </label>
              <p className="font-medium">{selectedProject.phone}</p>
            </div>
            <div>
              <label className="text-gray-500 text-sm flex items-center">
                <MapPin className="w-4 h-4 mr-1" />
                Address
              </label>
              <p className="font-medium">{selectedProject.address}</p>
            </div>
            <div>
              <label className="text-gray-500 text-sm flex items-center">
                <Briefcase className="w-4 h-4 mr-1" />
                Experience
              </label>
              <p className="font-medium">{selectedProject.experience} years</p>
            </div>
          </div>

          <div className="space-y-4">
          {selectedProject.linkedInProfile && (
  <div>
    <label className="text-gray-500 text-sm flex items-center">
      <Link className="w-4 h-4 mr-1" />
      LinkedIn Profile
    </label>
    <a
      href={selectedProject.linkedInProfile}
      target="_blank"
      rel="noopener noreferrer"
      className="text-emerald-600 hover:underline"
    >
      View Profile
    </a>
  </div>
)}

            <div>
              <label className="text-gray-500 text-sm flex items-center">
                <FileText className="w-4 h-4 mr-1" />
                Government ID
              </label>
              <a
                href={selectedProject.governmentId}
                target="_blank"
                rel="noopener noreferrer"
                className="text-emerald-600 hover:underline"
              >
                View Document
              </a>
            </div>
            <div>
              <label className="text-gray-500 text-sm flex items-center">
                <Globe className="w-4 h-4 mr-1" />
                Website
              </label>
              <a
                href={selectedProject.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-emerald-600 hover:underline"
              >
                Visit Website
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Professional Details */}
      <section className="bg-gray-50 rounded-xl p-6 shadow-sm">
        <h2 className="text-lg font-semibold mb-4 flex items-center">
          <Award className="w-5 h-5 mr-2 text-emerald-600" />
          Professional Details
        </h2>

        <div className="grid md:grid-cols-2 gap-6">
  <div className="space-y-4">
    {/* Certifications */}
    <div>
      <label className="text-gray-500 text-sm flex items-center">
        <Award className="w-4 h-4 mr-1" />
        Certifications
      </label>
      <div className="space-y-2 mt-1">
        {selectedProject.certifications.length > 0 ? (
          selectedProject.certifications.map((cert, index) => (
            <a
              key={index}
              href={cert}
              target="_blank"
              rel="noopener noreferrer"
              className="block text-emerald-600 hover:underline"
            >
              Certification {index + 1}
            </a>
          ))
        ) : (
          <p className="text-gray-700">N/A</p>
        )}
      </div>
    </div>

    {/* Reference Contact */}
    <div>
  <label className="text-gray-500 text-sm flex items-center">
    <Users className="w-4 h-4 mr-1" />
    Reference Contact
  </label>
  <p className="font-medium">
    {selectedProject.referenceContact ? selectedProject.referenceContact : "N/A"}
  </p>
</div>

  </div>

  <div className="space-y-4">
    {/* Previous Projects */}
    <div>
      <label className="text-gray-500 text-sm flex items-center">
        <Briefcase className="w-4 h-4 mr-1" />
        Previous Projects
      </label>
      <ul className="space-y-1 mt-1">
        {selectedProject.previousProjects.length > 0 ? (
          selectedProject.previousProjects.map((project, index) => (
            <li key={index} className="text-gray-700">
              {project}
            </li>
          ))
        ) : (
          <p className="text-gray-700">N/A</p>
        )}
      </ul>
    </div>
  </div>

        </div>
      </section>

      {/* Actions */}
      <div className="flex items-center justify-between pt-4 border-t">
        <button onClick={onBack} className="text-emerald-600 hover:underline">
          ← Back
        </button>
        {selectedProject.verificationStatus === "submitted" && (
          <div className="space-x-3">
            <button
              onClick={() => {
                setActionType("reject");
                setShowConfirmationModal(true);
              }}
              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              Reject
            </button>
            <button
              onClick={() => {
                setActionType("approve");
                setShowConfirmationModal(true);
              }}
              className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700"
            >
              Approve
            </button>
          </div>
        )}
      </div>

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-xl w-96">
            <p className="text-lg font-semibold mb-4">
              Are you sure you want to {actionType} this application?
            </p>

            {/* Conditionally Render Input for Rejection Reason */}
            {actionType === "reject" && (
              <div className="mb-4">
                <label
                  htmlFor="rejectionReason"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Please provide a reason for rejection:
                </label>
                <textarea
                  id="rejectionReason"
                  className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-emerald-500"
                  placeholder="Enter reason for rejection"
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)} // Handle input change
                />
              </div>
            )}

            <div className="flex justify-end space-x-3">
              <button
                disabled={modalLoader}
                onClick={() => setShowConfirmationModal(false)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 disabled:opacity-50"
              >
                Cancel
              </button>
              <button
                disabled={
                  modalLoader || (actionType === "reject" && !rejectionReason)
                }
                onClick={handleConfirmAction}
                className={`px-4 py-2 rounded-lg relative ${
                  actionType === "approve"
                    ? "bg-emerald-600 hover:bg-emerald-700"
                    : "bg-red-500 hover:bg-red-600"
                } text-white disabled:opacity-50`}
              >
                {modalLoader ? (
                  <span className="flex items-center justify-center">
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Processing...
                  </span>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManagerApplication;
