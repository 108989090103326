import React, { useEffect, useState } from 'react';
import { Search, Save, DollarSign, Trash2, Plus, X, ChevronDown, ChevronUp, Clock } from 'lucide-react';
import prevButton from "../../../src/images/Explore/prev.png";
import nextButton from "../../../src/images/Explore/next.png";
import ApiService from '../../service/ApiService';
import { apiPaths } from '../../service/apiPaths';
import { useCurrency } from './useCurrency';
import { Currencies } from './useCurrency';

interface PaginationState {
  currentPage: number;
  totalPages: number;
  totalDocs: number;  
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

interface RateHistory {
  rate: string;
  startDate: string;
  endDate: string | null;
}

interface NewCurrency {
  _id: string;
  name: string;
  symbol: string;
  code: string;
  rate: string;
}

// Update Currencies interface to include history
interface CurrencyWithHistory extends Currencies {
  history?: RateHistory[];
  startDate?: string;
  isActive?: boolean;
}

const CurrencyRates = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCurrency, setNewCurrency] = useState<NewCurrency>({
    _id: '',
    name: '',
    symbol: '',
    code: '',
    rate: ''
  });

  const [pagination, setPagination] = useState<PaginationState>({
    currentPage: 1,
    totalPages: 1,
    totalDocs: 0,
    hasNextPage: false,
    hasPrevPage: false,
  });

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [expandedCurrency, setExpandedCurrency] = useState<string | null>(null);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmDelete, setConfirmDelete] = useState<{ id: String | null; open: boolean }>({ id: null, open: false });

  const {handleSubmit, isSubmitting, setIsSubmitting, currencies, setCurrencies, getConversions, createCurrency, deleteCurrency} = useCurrency();

  useEffect(() => {
    getConversions();
  }, []);

  const handleRateChange = (_id: String, value: String) => {
    setCurrencies(prev => 
      prev.map(currency => 
        currency._id === _id ? { ...currency, rate: value } : currency
      )
    );
  };

  const handleDelete = (id: String) => {
    setConfirmDelete({ id, open: true });
  };

  const handleAddCurrency = async() => {
    setCurrencyLoading(true);
    const newId = Date.now().toString();

    const data = await createCurrency(newCurrency); 
    setCurrencyLoading(false);
    if(data) {
      setIsModalOpen(false);
      setNewCurrency({
        _id: '',
        name: '',
        symbol: '',
        code: '',
        rate: ''
      });
      getConversions();
    }
  };

  const toggleExpand = (id: string) => {
    if (expandedCurrency === id) {
      setExpandedCurrency(null);
    } else {
      setExpandedCurrency(id);
    }
  };

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'Present';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
  };

  const filteredCurrencies: CurrencyWithHistory[] =
    currencies && currencies.length > 0
      ? currencies.filter((currency) =>
          currency.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

  const getPageNumbers = (): number[] => {
    const totalPages = pagination.totalPages;
    const currentPage = pagination.currentPage;
    const pageNumbers: number[] = [];

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 4) {
        pageNumbers.push(1, 2, 3, 4);
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        );
      } else {
        pageNumbers.push(
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        );
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (pageNumber: number): void => {
    setPagination((prev) => ({ ...prev, currentPage: pageNumber }));
  };

  const handleNextPage = (): void => {
    if (pagination.hasNextPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = (): void => {
    if (pagination.hasPrevPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const confirmDeleteCurrency = async () => {
    if(confirmDelete.id){
      setDeleteLoading(true);
      await deleteCurrency(confirmDelete.id);
      setDeleteLoading(false);
      getConversions();
      setConfirmDelete({id: null, open: false});
    }
  };

  return (
    <div className="min-h-full bg-white rounded-xl">
      <div className="max-w-6xl mx-auto">
        <form onSubmit={handleSubmit} className="bg-white rounded-2xl overflow-hidden">
          {/* Header */}
          <div className="bg-gradient-to-r p-6">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-3xl font-bold text-black">Update Exchange Rates</h2>
                <p className="text-black-50 mt-1">Enter exchange rates for 1 Euro (€)</p>
              </div>
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="inline-flex items-center px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors"
              >
                <Plus className="w-4 h-4 mr-2" />
                Add Currency
              </button>
            </div>
          </div>

          {/* Search Bar */}
          <div className="p-4 border-b border-gray-200 flex justify-between items-center">
            <div className="relative max-w-md w-full">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                placeholder="Search currencies..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
              />
            </div>

            <div className="mt-0 flex justify-end">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`
                  inline-flex items-center gap-2
                  px-6 py-3
                  text-sm font-medium
                  bg-emerald-500
                  text-white
                  rounded-lg
                  transition-all duration-200
                  hover:bg-emerald-600
                  focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2
                  disabled:opacity-70 disabled:cursor-not-allowed
                  ${isSubmitting ? 'animate-pulse' : ''}
                `}
              >
                <Save className={`w-4 h-4 ${isSubmitting ? 'animate-spin' : ''}`} />
                {isSubmitting ? 'Updating...' : 'Update Rates'}
              </button>
            </div>
          </div>

          {/* Table */}
          <div className="p-4">
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Symbol</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Name</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Rate</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Start Date</th>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-gray-600">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredCurrencies && filteredCurrencies.length > 0 && filteredCurrencies.map(currency => (
                    <React.Fragment key={currency._id.toString()}>
                      <tr className="hover:bg-gray-50 transition-colors">
                        <td className="px-4 py-3 text-gray-600">{currency.symbol}</td>
                        <td className="px-4 py-3 text-gray-600">{currency.name}</td>
                        <td className="px-4 py-3">
                          <input
                            type="number"
                            value={Number(currency.rate)}
                            onChange={(e) => handleRateChange(currency._id, e.target.value)}
                            placeholder="Enter rate"
                            className="w-32 p-1 bg-white border border-gray-200 rounded focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                            step="0.0001"
                            required
                          />
                        </td>
                        <td className="px-4 py-3 text-gray-600">
                          <div className="flex items-center">
                            <Clock className="w-4 h-4 mr-2 text-gray-400" />
                            {currency.startDate ? formatDate(currency.startDate) : 'N/A'}
                          </div>
                        </td>
                        <td className="px-4 py-3 flex space-x-2">
                          <button
                            type="button"
                            onClick={() => toggleExpand(currency._id.toString())}
                            className="text-gray-400 hover:text-blue-500 transition-colors"
                            title="Show history"
                          >
                            {expandedCurrency === currency._id.toString() ? (
                              <ChevronUp className="w-5 h-5" />
                            ) : (
                              <ChevronDown className="w-5 h-5" />
                            )}
                          </button>
                          <button
                            type="button"
                            onClick={() => handleDelete(currency._id)}
                            className="text-gray-400 hover:text-red-500 transition-colors"
                            title="Delete currency"
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        </td>
                      </tr>
                      
                      {/* History expansion row */}
                      {expandedCurrency === currency._id.toString() && (
                        <tr>
                          <td colSpan={5} className="bg-gray-50 px-4 py-3">
                            <div className="py-2">
                              <h4 className="font-medium text-gray-700 mb-2">Rate History</h4>
                              {currency.history && currency.history.length > 0 ? (
                                <div className="overflow-x-auto">
                                  <table className="w-full border-collapse">
                                    <thead>
                                      <tr className="bg-gray-100">
                                        <th className="px-4 py-2 text-left text-xs font-semibold text-gray-600">Rate</th>
                                        <th className="px-4 py-2 text-left text-xs font-semibold text-gray-600">Start Date</th>
                                        <th className="px-4 py-2 text-left text-xs font-semibold text-gray-600">End Date</th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                      {/* Current rate */}
                                      <tr className="bg-emerald-50">
                                        <td className="px-4 py-2 text-gray-600">{currency.rate}</td>
                                        <td className="px-4 py-2 text-gray-600">{formatDate(currency.startDate || '')}</td>
                                        <td className="px-4 py-2 text-gray-600">Present</td>
                                      </tr>
                                      {/* Historical rates */}
                                      {currency.history.map((item, index) => (
                                        <tr key={index} className="hover:bg-gray-100">
                                          <td className="px-4 py-2 text-gray-600">{item.rate}</td>
                                          <td className="px-4 py-2 text-gray-600">{formatDate(item.startDate)}</td>
                                          <td className="px-4 py-2 text-gray-600">{formatDate(item.endDate)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <p className="text-sm text-gray-500">No history available for this currency.</p>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>

              {filteredCurrencies.length === 0 && (
                <div className="text-center py-12">
                  <p className="text-gray-500">No currencies found. Try adjusting your search.</p>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>

      {/* Modal Overlay */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          {/* Modal Content */}
          <div className="bg-white rounded-lg w-full max-w-md mx-4 overflow-hidden">
            {/* Modal Header */}
            <div className="flex justify-between items-center border-b p-4">
              <h3 className="text-lg font-semibold">Add New Currency</h3>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {/* Modal Body */}
            <div className="p-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Currency Name
                </label>
                <input
                  type="text"
                  value={newCurrency.name}
                  onChange={(e) => setNewCurrency(prev => ({ ...prev, name: e.target.value }))}
                  placeholder="e.g. US Dollar"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Symbol
                </label>
                <input
                  type="text"
                  value={newCurrency.symbol}
                  onChange={(e) => setNewCurrency(prev => ({ ...prev, symbol: e.target.value }))}
                  placeholder="e.g. $"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                />
              </div>

              {/* <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Code
                </label>
                <input
                  type="text"
                  value={newCurrency.code}
                  onChange={(e) => setNewCurrency(prev => ({ ...prev, code: e.target.value }))}
                  placeholder="e.g. USD"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                />
              </div> */}

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Rate
                </label>
                <input
                  type="number"
                  step="0.0001"
                  value={newCurrency.rate}
                  onChange={(e) => setNewCurrency(prev => ({ ...prev, rate: e.target.value }))}
                  placeholder="e.g. 1.0000"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                />
              </div>
            </div>

            {/* Modal Footer */}
            <div className="border-t p-4 flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleAddCurrency}
                disabled={currencyLoading}
                className={`
                  px-4 py-2 rounded-lg transition-colors
                  ${currencyLoading 
                  ? 'bg-emerald-200 text-gray-500 cursor-not-allowed'
                  : 'bg-emerald-500 text-white hover:bg-emerald-600'
                  }
                `}
              >
                {currencyLoading? 'Adding...': 'Add Currency'}
              </button>
            </div>
          </div>
        </div>
      )}

      {confirmDelete.open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm mx-4">
            <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
            <p className="mb-4">Are you sure you want to delete this currency?</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setConfirmDelete({ id: null, open: false })}
                className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeleteCurrency}
                className={`
                  px-4 py-2 rounded-lg transition-colors
                  ${deleteLoading
                  ? 'bg-red-200 text-gray-500 cursor-not-allowed'
                  : 'bg-red-500 text-white hover:bg-red-600'
                  }
                `}
                disabled={deleteLoading}
              >
                {deleteLoading? 'Deleting': 'Delete'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrencyRates;