import React, { useEffect, useState } from "react";
import prevButton from "../../images/Explore/prev.png";
import nextButton from "../../images/Explore/next.png";
import { useNavigate } from "react-router-dom";
import { useGetInvestmentCutoff } from './useInvestmentCutoff';

export interface InvestmentCutoff {
  _id: string;
  duration: number;
  description: string;
  projectId: string;
  status: string;
  createdAt: string;
}

interface PaginationState {
  currentPage: number;
  totalPages: number;
  totalDocs: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

const InvestmentCutoffPage = () => {
  const [cutoffs, setCutoffs] = useState<InvestmentCutoff[]>([]);
  const {
    getAllInvestmentCutoffs,
    getAcceptedInvestmentCutoffs ,
    getRejectedInvestmentCutoffs ,
    loading
  } = useGetInvestmentCutoff(); // Assuming you have a hook for fetching investment cutoffs
  const [pagination, setPagination] = useState<PaginationState>({
    currentPage: 1,
    totalPages: 1,
    totalDocs: 0,
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [filter, setFilter] = useState<string>("active"); // Default filter is "all"

  const navigate = useNavigate();

  const handleNavigation = (cutoffId: string) => {
    navigate(`/investmentcutoff/${cutoffId}`);
  };

  const dateSanitizer = (mongoDate: string) => {
    const date = new Date(mongoDate);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${
      (date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    return formattedDate;
  };

  useEffect(() => {
    async function fetchCutoffs() {
      try {
        let response:any ; 

        if(filter==="active"){
         response = await getAllInvestmentCutoffs(pagination.currentPage.toString());
        }else if(filter==="accepted"){
          response = await getAcceptedInvestmentCutoffs(pagination.currentPage.toString())

        }else{
          response = await getRejectedInvestmentCutoffs(pagination.currentPage.toString())

        }
        if (response) {
          setCutoffs(response.docs || []);
          setPagination((prev) => ({
            ...prev,
            totalPages: response.totalPages || 1,
            totalDocs: response.totalDocs || 0,
            hasNextPage: response.hasNextPage || false,
            hasPrevPage: response.hasPrevPage || false,
          }));
        }
      } catch (error) {
        console.error("Failed to fetch investment cutoffs", error);
      }
    }

    fetchCutoffs();
  }, [pagination.currentPage, filter]); // Add filter to the dependency array

  const getPageNumbers = (): number[] => {
    const totalPages = pagination.totalPages;
    const currentPage = pagination.currentPage;
    const pageNumbers: number[] = [];

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 4) {
        pageNumbers.push(1, 2, 3, 4);
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        );
      } else {
        pageNumbers.push(
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
        );
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (pageNumber: number): void => {
    setPagination((prev) => ({ ...prev, currentPage: pageNumber }));
  };

  const handleNextPage = (): void => {
    if (pagination.hasNextPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }));
    }
  };

  const handlePrevPage = (): void => {
    if (pagination.hasPrevPage) {
      setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }));
    }
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
    setPagination((prev) => ({ ...prev, currentPage: 1 })); // Reset to the first page
  };

  return (
    <>
      <div className="bg-[#fff] min-h-full p-4 rounded-[16px]">
        <h1 className="text-[20px] font-semibold">Investment Cutoffs</h1>
        <p className="text-[14px] text-[#9e9b9b] mt-2">
          View a list of investment cutoffs. Gain insights into their durations and descriptions.
        </p>

        {/* Filter Dropdown */}
        <div className="flex justify-end mb-4">
          <select
            value={filter}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="border px-3 py-2 rounded-lg"
          >
            <option value="active">Active Cutoffs</option>
            <option value="accepted">Accepted Cutoffs</option>
            <option value="rejected">Rejected Cutoffs</option>
          </select> 
        </div>

        {loading ? (
          <div className="w-full h-[16rem] flex justify-center items-center">
            <p>Loading..</p>
          </div>
        ) : ( 
          <>
            {cutoffs && cutoffs.length > 0 ? (
              <div className="bg-white border rounded-lg overflow-hidden mt-4">
                <h1 className="ml-3 my-3 font-semibold">Investment Cutoffs</h1>
                <div className="overflow-x-auto">
                  <table className="min-w-full shadow-sm">
                    <thead>
                      <tr className="bg-gray-100 text-gray-600 text-sm uppercase">
                        <th className="py-3 px-6 text-left">Sr. No.</th>
                        <th className="py-3 px-6 text-left">Duration</th>
                        <th className="py-3 px-6 text-left">Description</th>
                        <th className="py-3 px-6 text-left">Status</th>
                        <th className="py-3 px-6 text-left">Date</th>
                        <th className="py-3 px-6 text-left">View </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                      {cutoffs.map((cutoff, index) => (
                        <tr
                          key={cutoff._id}
                          className="border-b border-gray-200 hover:bg-gray-50"
                          onClick={() => handleNavigation(cutoff._id)} // Set selected cutoff on click
                        >
                          <td className="py-3 px-6">
                            {(pagination.currentPage - 1) * 4 + index + 1}
                          </td>
                          <td className="py-3 px-6">{cutoff?.duration}</td>
                          <td className="py-3 px-6">{cutoff?.description}</td>
                          <td className="py-3 px-6">{cutoff?.status}</td>
                          <td className="py-3 px-6">
                            {dateSanitizer(cutoff?.createdAt)}
                          </td>
                          <td className="py-3 px-6 text-green-400 hover:cursor-pointer" onClick={()=>{navigate(`/cutoff-details/:${cutoff._id}`)}}>
                            View
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="w-full h-[16rem] flex items-center justify-center">
                <p className="text-xl">No Investment Cutoff Found</p>
              </div>
            )}

            {/* Pagination */}
            {cutoffs.length > 0 && (
              <div className="flex w-full justify-center mx-auto gap-2 mt-6 mb-1">
                <button
                  onClick={handlePrevPage}
                  disabled={!pagination.hasPrevPage}
                  className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasPrevPage ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  <img
                    className="h-[10px] w-[7px]"
                    src={prevButton}
                    alt="Previous"
                  />
                </button>

                {getPageNumbers().map((pageNumber, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`h-[40px] w-[40px] border flex items-center justify-center rounded-full ${pagination.currentPage === pageNumber ? "bg-[#56BBAC] text-white" : ""}`}
                  >
                    {pageNumber}
                  </button>
                ))}

                <button
                  onClick={handleNextPage}
                  disabled={!pagination.hasNextPage}
                  className={`h-[40px] w-[40px] border border-[#56BBAC] flex items-center justify-center rounded-full ${!pagination.hasNextPage ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  <img
                    className="h-[10px] w-[7px]"
                    src={nextButton}
                    alt="Next"
                  />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default InvestmentCutoffPage;