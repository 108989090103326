/**
 * API paths for various CRUD operations
 */
export const apiPaths = {
  //Auth API list
  Login: "login",
  Signup: "signup",
  ForgotPassword: "forgot-password",
  VerifyOtp: "verify-otp",
  CreateProject: "api/v1/projects",
  ResendOtp: "resend-otp",
  Profile: "api/v1/profile",
  ListProject: "api/v1/projects",
  getProjects: "api/v1/projects",
  refreshToken: "refresh-token",
  checkTokens: "check-tokens",
  sendOtp: "send-otp",
  SetPassword: "set-password",

  getActiveInvestors: "api/v1/investors/active",
  getInvestorTransactions: "api/v1/transactions/investor",
  getProjectManagerTransactions: "api/v1/transactions/project-manager",

  subscribeNotification: "api/v1/subscribe-notifications/subcribe",
  getNotifications: "api/v1/subscribe-notifications",

  timeZonePreferences: "api/v1/profile/preferences",

  wallets: "api/v1/wallets",
  imageUpload: "api/v1/uplode",
  becomeProjectManager: "api/v1/project-managers/user/application",
  changePassword: "change-password",

  //admin  paths

  getAllProjectsManager: "api/v1/project-managers",
  processProjectManagerApplication: "api/v1/project-managers",
  getRejectedApplications: "api/v1/project-managers/rejected",
  getAcceptedApplications: "api/v1/project-managers/getAccepted/accepted",
  getProjectManagerById: "api/v1/project-managers",

  getAllProjects: "api/v1/projects",
  processProjectRequest: "api/v1/projects",
  getRejectedProjects: "api/v1/projects/rejected",
  getExperiencedProjects: "api/v1/projects/experienced",
  getAccepetedProjects: "api/v1/projects/getAccepted/accepted",
  getProjectById: "api/v1/projects",

  //withdrawals
  getWithdrawals: "api/v1/withdrawal",
  getRejectedWithdrawals:"api/v1/withdrawal/rejected" ,
  getAcceptedWithdrawals:"api/v1/withdrawal/accepted" ,



  //getInvestmentCutoff
  getAllInvestmentCutoffs:"api/v1/investment-cutoff" ,
  getAcceptedInvestmentCutoffApplication:"api/v1/investment-cutoff/accepted" ,
  getRejectedInvestmentCutoffApplication:"api/v1/investment-cutoff/rejected" ,


  addDocumentation: (applicationId: string) =>
    `api/v1/withdrawal/${applicationId}/approve`,

  Logout: "logout",

   //conversion routes
   createCurrency :"api/v1/conversion" ,

  updateValues :"api/v1/conversion/update" ,
  getValues:"api/v1/conversion" ,
  deleteConversion:"api/v1/conversion"

};
